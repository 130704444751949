<template>
  <div class="row">
    <div class="col-12 col-lg-3">
      <div class="card">
        <div class="d-flex flex-column align-items-center mt-5 mb-4">
          <img
            width="100"
            height="100"
            class="rounded"
            :src="
              state.akun.profilePicture
                ? state.backendUrl + state.akun.profilePicture
                : `https://ui-avatars.com/api/?background=3e4095&color=fff&name=${
                    state.akun.firstName + ' ' + (state.akun.lastName ? state.akun.lastName : '')
                  }`
            "
            alt=""
          />
          <h5 class="mt-3">
            {{ state.akun.firstName + ' ' + (state.akun.lastName ? state.akun.lastName : '') }}
          </h5>
          <p class="text-secondary">{{ state.akun.email || '-' }}</p>
        </div>
        <ul class="list-unstyled mb-0">
          <li
            v-for="menu in menus"
            :key="menu.name"
            @click.prevent="onChangeMenu(menu.name)"
            :class="[$style.item, state.menuActive === menu.name ? $style.active : '']"
          >
            <div :class="$style.itemLink">
              <div :class="$style.itemPic">
                <i :class="menu.icon" />
              </div>
              <div class="mr-2">
                <div class="">{{ menu.name }}</div>
                <!-- <div class="text-muted">{{ menu.total }} {{ menu.addition }}</div> -->
              </div>
              <div :class="$style.itemAction">
                <span />
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-12 col-lg-9">
      <div class="card">
        <div class="card-header">
          <h5>{{ state.menuActive }}</h5>
        </div>
        <div class="card-body mt-2">
          <daftar-wishlist
            v-if="state.menuActive === 'Daftar Wishlist'"
            :wishlists="state.akun.myWishlists"
          />
          <daftar-course
            v-if="state.menuActive === 'Daftar Akses Course'"
            :user-id="state.akun._id"
          />
          <daftar-transaksi
            v-if="state.menuActive === 'Daftar Transaksi'"
            :user-id="state.akun._id"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent, onMounted, reactive, toRefs } from '@vue/runtime-core';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
const DaftarWishlist = defineAsyncComponent(() => import('@/components/MyWishlist'))
const DaftarCourse = defineAsyncComponent(() => import('@/components/MyCourse'))
const DaftarTransaksi = defineAsyncComponent(() => import('@/components/MyTransaction'))
const menus = [
  {
    name: 'Daftar Wishlist',
    total: 0,
    addition: 'wishlist',
    icon: 'fe fe-bookmark',
  },
  {
    name: 'Daftar Akses Course',
    total: 0,
    addition: 'course',
    icon: 'fe fe-monitor',
  },
  {
    name: 'Daftar Transaksi',
    total: 0,
    addition: 'transaksi',
    icon: 'fe fe-dollar-sign',
  },
]
export default {
  components: {
    DaftarWishlist,
    DaftarCourse,
    DaftarTransaksi,
  },

  setup() {
    const store = useStore()
    const route = useRoute()
    let state = reactive({
      backendUrl: process.env.VUE_APP_BACKEND_URL,
      menuActive: 'Daftar Wishlist',
      akun: {
        _id: route.params.id,
        firstName: '',
        lastName: '',
        email: '',
        profilePicture: '',
        myWishlists: [],
      },
    })

    const onChangeMenu = ((menu) => {
      state.menuActive = menu
    })

    const fetchDetail = (async () => {
      const id = route.params.id
      state.akun = await store.dispatch('akun/FETCH_DETAIL_AKUN', id)
    })

    onMounted(() => fetchDetail())
    return {
      state,
      menus,
      onChangeMenu,
    }
  },
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>